// 开发环境地址
const config = {
  dataCenter: "http://localhost:4008",
  supplyChain: "http://localhost:4009",
  channelLine: "http://localhost:4010",
  tradeCenter: "http://localhost:4011",
  marketingCenter: "http://localhost:4012",
  businessOnline: "http://localhost:4013",
  customerService: "http://localhost:4014",
};

// 线上环境地址
if (process.env.NODE_ENV === "production") {
  // 基座应用和子应用部署在同一个域名下，这里使用location.origin进行补全
  Object.keys(config).forEach((key) => {
    config[key] = window.location.origin;
  });
}

export default config;
